// Game.tsx
import React, { useState, useEffect } from 'react';

export const Game1: React.FC = () => {
  const [score, setScore] = useState(0);
  const [position, setPosition] = useState({ top: 0, left: 0 });
  const [timeLeft, setTimeLeft] = useState(30);
  const [isGameActive, setIsGameActive] = useState(false);

  useEffect(() => {
    if (isGameActive && timeLeft > 0) {
      const timer = setInterval(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearInterval(timer);
    } else if (timeLeft === 0) {
      setIsGameActive(false);
    }
  }, [isGameActive, timeLeft]);

  const startGame = () => {
    setScore(0);
    setTimeLeft(30);
    setIsGameActive(true);
    moveButton();
  };

  const moveButton = () => {
    const top = Math.floor(Math.random() * 80) + 10;
    const left = Math.floor(Math.random() * 80) + 10;
    setPosition({ top, left });
  };

  const handleClick = () => {
    if (isGameActive) {
      setScore(score + 1);
      moveButton();
    }
  };

  return (

    <div className="game" aria-labelledby="game-title" role="region">
      <h2 id="game-title">Click the Button Game!</h2>
      <p aria-live="polite">Time Left: {timeLeft}s</p>
      <p aria-live="polite">Score: {score}</p>
      <button
        className="game__button"
        onClick={handleClick}
        style={{ top: `${position.top}%`, left: `${position.left}%` }}
        aria-label="Click the button to score points"
      >
        Click me!
      </button>
      {!isGameActive && (
        <button
          className="game__start-button"
          onClick={startGame}
          aria-label="Start the Click the Button Game"
        >
          Start Game
        </button>
      )}
    </div>
  );
};
