import { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import Content from './Content';
import { Header } from '../home/Header';
import { Footer } from '../home/Footer';

export class Layout extends Component {
    render() {

        return (
            <div>
                {/* <Header /> */}
                <Content />
                {/* <Footer /> */}
            </div >
        );
    }
}
