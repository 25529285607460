import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { Home } from '../home/Home';
import { Game1 } from '../games/Game1';
import { Game2 } from '../games/Game2';

let Content = () => {
    let navigate = useNavigate()
    let param = useParams()

    return (
        <div>
            <Routes>

                {/* impex */}
                <Route path='/home' element={<Home />}></Route>

                <Route path='/click-me' element={<Game1 />}></Route>
                <Route path='/Tic-Tac-Toe' element={<Game2 />}></Route>


            </Routes>
        </div>
    );
}

export default Content;