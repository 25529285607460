import './App.css';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Layout } from './components/layouts/Layout';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          
          {/* Impex Layout */}
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/*" element={<Layout />} />

          {/* 404 bugfix: redirect to home page */}
          <Route path="*" element={<Navigate to="/home" />} />

        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
