import { Component } from "react";
import { Game1 } from "../games/Game1";
import { Link } from "react-router-dom";

export class Home extends Component {
  render() {
    return (
      <div className="homepage-container">
        <h1 className="title">Welcome to the Game Hub</h1>
        <p className="description">
          Discover a variety of fun and engaging games! Click on a link below to start playing.
        </p>
        <div className="link-container">
          <Link to="/Click-me" className="game-link" aria-label="Play Game 1: Click me">
            <span className="link-text">Play Game 1</span>
          </Link>
          <Link to="/Tic-Tac-Toe" className="game-link" aria-label="Play Game 2: X O">
            <span className="link-text">Play X O</span>
          </Link>
        </div>
      </div>
    )
  }
}