// Game.tsx
import React, { useState } from 'react';

type Player = 'X' | 'O' | null;

const checkWinner = (board: Player[]): Player | 'Draw' | null => {
  const winningCombinations = [
    [0, 1, 2], [3, 4, 5], [6, 7, 8], // Rows
    [0, 3, 6], [1, 4, 7], [2, 5, 8], // Columns
    [0, 4, 8], [2, 4, 6]             // Diagonals
  ];

  for (const [a, b, c] of winningCombinations) {
    if (board[a] && board[a] === board[b] && board[a] === board[c]) {
      return board[a];
    }
  }

  return board.every(square => square) ? 'Draw' : null;
};

export const Game2: React.FC = () => {
  const [board, setBoard] = useState<Player[]>(Array(9).fill(null));
  const [currentPlayer, setCurrentPlayer] = useState<Player>('X');
  const [winner, setWinner] = useState<Player | 'Draw' | null>(null);

  const handleSquareClick = (index: number) => {
    if (!board[index] && !winner) {
      const newBoard = [...board];
      newBoard[index] = currentPlayer;
      setBoard(newBoard);

      const gameWinner = checkWinner(newBoard);
      setWinner(gameWinner);

      setCurrentPlayer(currentPlayer === 'X' ? 'O' : 'X');
    }
  };

  const resetGame = () => {
    setBoard(Array(9).fill(null));
    setCurrentPlayer('X');
    setWinner(null);
  };

  return (
    <div className="game2" role="region" aria-labelledby="tic-tac-toe-title">
      <h2 id="tic-tac-toe-title">Tic-Tac-Toe</h2>
      <div className="game2__board" role="grid" aria-label="Tic Tac Toe game board">
        {board.map((value, index) => (
          <button
            key={index}
            className="game2__square"
            onClick={() => handleSquareClick(index)}
            role="gridcell"
            aria-label={`Square ${index + 1}, current value: ${value || 'empty'}`}
            disabled={!!winner} // Disable squares if there's a winner
          >
            {value}
          </button>
        ))}
      </div>
      {winner && (
        <div className="game2__status" role="alert">
          {winner === 'Draw' ? 'It’s a Draw!' : `Player ${winner} Wins!`}
          <button onClick={resetGame} className="game2__reset-button" aria-label="Reset the game">
            Play Again
          </button>
        </div>
      )}
      {!winner && <p aria-live="polite">Current Turn: Player {currentPlayer}</p>}
    </div>
  );
};
